<template>
	<DataDialog ref="dialogRef" :title="$t('text.personalisationFields')" v-model="model" :onConfirm="onConfirm">
		<template #content="{ model }">
			<div class="field left-border">
				<v-label>{{ $t('text.fixedPersonalisationFields') }}</v-label>
				<p class="helpText">{{ $t('text.fixedPersonalisationFieldsHelpText') }}</p>

				<div v-for="option in personalisationOptions" :key="`checkbox-${option}`" class="checkbox-wrap">
					<v-checkbox v-model="model[option]" :label="$t(`text.${camelCase(option)}Personalisation`)"
						hide-details color="blue" />
				</div>
			</div>
		</template>
	</DataDialog>
</template>

<script>
import camelCase from 'lodash/camelCase'
import DataDialog from '@/components/common/DataDialog.vue'

export default {
	name: 'PersonalisationsDialog',
	components: { DataDialog },
	emits: ['update:modelValue'],
	props: {
		modelValue: Object,
	},
	data() {
		return {
			model: {
				first_name: false,
				last_name: false,
				birth_date: false,
				phoneNumber: false,
				gender: false,
				street: false,
				streetNumber: false,
				zipCode: false,
				city: false,
				country: false,
				email: false,
				photo: false,
			},
		}
	},
	watch: {
		modelValue: {
			handler: function (value) {
				if (!value) return

				this.model = {
					...this.model,
					...value,
				}
			},
			immediate: true,
		},
	},
	computed: {
		personalisationOptions() {
			return Object.keys(this.model)
		},
	},
	methods: {
		open() {
			this.$refs.dialogRef.open()
		},
		onConfirm(value) {
			this.$emit('update:modelValue', value)
		},
	},
	setup() {
		return {
			camelCase,
		}
	},
}
</script>

<style lang="scss" scoped>
.checkbox-wrap {
	border-bottom: 1px solid #cfd9e0;
}
</style>

<style lang="scss">
.v-label {
	color: black !important;
}
</style>
