<template>
	<div>
		<FieldSet id="skipassAgeGroupMapping" infoText="skipassAgeGroupMappingHelp">
			<div class="skipassAgeGroupMappingTable">
				<div :class="{ 'mapping-missing-warning': mappingMissing }">
					<AddButton @click="openEditDialog" buttonText="editSkipassAgeGroupMappingDialog" :update="true" style="margin-right: 10px;" dataCy="edit-skipass-age-group-mapping"  />
					<v-progress-linear indeterminate v-show="loading" color="orange"></v-progress-linear>
					<SectionTable
						:items="skipassAddedValueItems"
						:hideFooter="true"
						:showExpand="true"
						:columns="[
							{ id: 'product', type: 'slot' },
							{ id: '', type: 'expand', canAdd: false },
						]"
						no-data-text="noAddedValuesAdded"
						@expand-row="getRegionsAndAgeGroupsForNewSkipassAddedValue"
					>
						<template #column-product="{ item }">
							<div>
								<div class="subtitle" >
									{{item.product.spName}} | {{$t(`text.${item.product.type}`)}} | {{ item.product.skus.join(', ') }}
								</div>
								<div class="row-text">{{ item.product.main }}</div>
							</div>
						</template>

						<template #content-warning="{ item }">
							<div v-if="hasMissingMappings(item)" style="padding-left: 5px">
								<v-sheet class="filled-warning" border="warning lg" rounded><b>{{ $t('text.mappingMissing') }}</b></v-sheet>
							</div>
						</template>

						<template #expanded-row="{ item }">
							<div class="expanded-content">
								<SectionTable
									hideFooter
									:items="getFilteredModelForItem(item)"
									typeName="SkipassAgeGroupMapping"
									:columns="[
										{ id: 'skipassTicketType', type: 'slot', fieldName: 'skipassAgeGroup', width: '33%', headerInfo: 'skipassAgeGroupHeaderInfo' },
										{ id: 'region', type: 'slot', fieldName: 'skipassRegion', width: '33%', headerInfo: 'skipassRegionHeaderInfo' },
										{ id: 'packageTicketType', type: 'slot', fieldName: 'packageTravelAgeGroup', width: '33%', headerInfo: 'packageTravelAgeGroupHeaderInfo' },
									]"
									no-data-text="noSkipassAgeGroupMappingsToShow"
									:hiddenCheck="(innerItem) => isHidden(innerItem, item.product.regions)"
								>
									<template #column-skipassTicketType="{ item: innerItem }">
										{{ getOptionLabel(innerItem.skipassAgeGroup.de, options) }}
									</template>
									<template #column-region="{ item: innerItem }">
										{{ getOptionLabel(innerItem.skipassRegion.de, regionOptions) }}
									</template>
									<template #column-packageTicketType="{ item: innerItem }">
										{{ getOptionLabel(innerItem.packageTravelAgeGroup.de, ageGroupOptions) }}
									</template>
								</SectionTable>
							</div>
						</template>
					</SectionTable>
				</div>
			</div>
			<div v-if="mappingMissing" class="warning-help-text">
				{{$t('text.skipassMappingWarningHelpText')}}
			</div>
		</FieldSet>

		<DataDialog ref="editSkipassAgeGroupMappingDialog" v-model="editModel" :title="$t('text.editSkipassAgeGroupMappingDialog')"
			:confirmLabel="$t('text.confirmSelection')" height="95vh" width="70vw" :fixIndexes="false" @confirm="handleDialogConfirm">
			<template #content>
				<v-progress-linear indeterminate v-show="loading" color="green"></v-progress-linear>
				<div class="gapped" :class="{ overlayed: loading }">
					<FieldSet id="globalSetting" mdiIcon="earth" style="padding-bottom: 20px;"></FieldSet>
					<FieldSet id="skipassAgeGroupMapping" infoText="skipassAgeGroupMappingHelp" style="padding-bottom: 20px;">
						<SectionTable
							:items="editModel"
							:columns="[
								{ id: 'region', type: 'slot', fieldName: 'skipassRegion', width: '30%', headerInfo: 'regionHeaderInfo' },
								{ id: 'skipassTicketType', type: 'slot', fieldName: 'skipassAgeGroup', width: '30%', headerInfo: 'skipassTicketTypeHeaderInfo' },
								{ id: 'packageTicketType', type: 'slot', fieldName: 'packageTravelAgeGroup', width: '70%', headerInfo: 'packageTicketTypeHeaderInfo' },
							]"
							no-data-text="noSkipassAgeGroupMappingsToShow"
							style="padding-bottom: 20px;"
						>
							<template #column-region="{ item }">
								<div>
									{{ getOptionLabel(item.skipassRegion.de, regionOptions) }}
								</div>
							</template>
							<template #column-skipassTicketType="{ item }">
								{{ getOptionLabel(item.skipassAgeGroup.de, options) }}
							</template>
							<template #column-packageTicketType="{ item }">
								<v-select
									v-model="item.packageTravelAgeGroup.de"
									:items="ageGroupOptions"
									:item-title="option => option.label?.de"
									:item-value="option => option.key?.de"
									hide-details
									density="comfortable"
									variant="outlined"
									data-cy="age-group-dropdown"
								>
									<template v-slot:item="{ props, item: innerItem }">
										<v-list-item
											v-bind="props"
											:disabled="isOptionDisabled(innerItem, item)"
										>
										{{ item.label }}
										</v-list-item>
									</template>
								</v-select>
							</template>
							<template #row-divider="{ index }">
								<div class="region-divider" v-if="index === editModel.length - 1 || editModel[index].skipassRegion.de !== editModel[index + 1].skipassRegion.de"></div>
							</template>
						</SectionTable>
					</FieldSet>
				</div>
			</template>
		</DataDialog>
	</div>
</template>

<script lang="ts">
import FieldSet from '../../views/applications/packageDesigner/FieldSet.vue'
import Field from './Field.vue'
import SectionTable from '../../views/applications/packageDesigner/SectionTable.vue'
import Common from '../../mixins/Common.vue'
import { load } from './loader'
import AgeGroupField from './AgeGroupField.vue'
import AddButton from '../../views/applications/packageDesigner/AddButton.vue'
import DataDialog from '../common/DataDialog.vue'
import eventBus from '@/utils/eventBus.js'

type AddedValueItem = {
	id: string
	product: {
		main: string
		type: string
		spName: string
		skus: string[]
		content_id: string
		product_id: string
		regions: { key: string, label: string }[]
		skipassAgeGroups: { key: string, label: string }[]
	}
	addl: any
	content: Content
}

export async function loadDimensions(_this: any, clientId): Promise<{
		duration: { options: any[] }
		ageGroup: { options: any[] }
		skipassAgeGroupMapping: { options: any[], model: any[] }
	}> {
	return await load('DIMS-' + clientId, async () => {
		try {
			const r = await _this.$httpGet(`/packageTravel/dimensions?clientId=${clientId}`)
			r.ageGroup.options.sort((a, b) => (a.label[_this.serviceLocale] ?? '').localeCompare(b.label[_this.serviceLocale]))
			r.duration.options.sort((a, b) => (a.label[_this.serviceLocale] ?? '').localeCompare(b.label[_this.serviceLocale]))
			return r
		}
		catch (error) {
			console.error(error)
		}
	})
}

export default {
	components: { FieldSet, Field, SectionTable, AgeGroupField, AddButton, DataDialog },
	mixins: [ Common ],
	inject: ['injectedData'],
	props: {
		linkedEntries: Object,
	},
	data() {
		return {
			model: [],
			editModel: [],
			mappingMissing: false,
			options: [],
			regionOptions: [],
			modelChanged: false,
			initialLoad: true,
			allAgeGroups: [],
		}
	},
	computed: {
		addedValueProducts() {
			return this.injectedData.packageDetails?.fields?.addedValues?.de ?? []
		},
		skipassAddedValueItems(): AddedValueItem[] {
			const skipassItems = this.addedValueProducts.map(({ sys: { id } }, index) => {
				const item = this.linkedEntries[id]
				if (!item.fields.skipassProducts?.de?.length) return null
				return {
					id: item.sys.id,
					product: {
						main: item.fields.name[this.serviceLocale] ?? item.fields.name.de ?? '-',
						type: 'skipass',
						spName: item.fields.serviceProviderName?.de?.filter(val => !!val)?.[0] ?? '-',
						skus: item.fields.skipassProducts?.de ?? [],
						regions: item.fields.regions?.de ?? [],
						skipassAgeGroups: item.fields.skipassAgeGroups?.de ?? [],
					},
				}
			}).filter(item => item)

			return skipassItems
		},
		ageGroupOptions() {
			const ticketAgeGroups = this.injectedData.packageDetails?.fields?.tickets?.de?.map(ticket => ticket.fields.ageGroup.de) ?? []
			const filteredOptions = this.allAgeGroups.filter(option => ticketAgeGroups.includes(option.key.de))
			filteredOptions.unshift({ key: {de: null}, label: {de: null} })
			return filteredOptions
		},
	},
	watch: {
		model: {
			deep: true,
			handler(n) {
				this.$nextTick(() => {	
					if (!this.initialLoad) {
						this.modelChanged = true
						this.checkMappingMissing()
					}
				})
			},
		},
		'injectedData.packageDetails.fields.tickets.de': {
			deep: true,
			async handler(n) {
				console.log('packageDetails changed, checking for missing mappings in the skipass age group mapping', n)
				this.checkMappingMissing()
			}
		},
	},
	methods: {
		async loadOptions() {
			this.loading = true
			const clientId = this.$store.state.selectedClient.sys.id
			const r = await loadDimensions(this, clientId)
			if (r) {
				this.options = r.skipassAgeGroupMapping.options
				this.allAgeGroups = r.ageGroup.options
				if (!this.model.length) this.model = r.skipassAgeGroupMapping.model
				this.regionOptions = this.getUniqueRegions()
				this.initializeModel()
			}
			this.checkMappingMissing()
			this.loading = false
		},
		getUniqueRegions() {
			const uniqueRegions = new Set()
			this.skipassAddedValueItems.forEach(item => {
				item.product.regions.forEach(region => {
					uniqueRegions.add(JSON.stringify(region))
				})
			})
			return Array.from(uniqueRegions).map(region => JSON.parse(region))
		},
		initializeModel() {
			const updatedModel = [...this.model]
			this.skipassAddedValueItems.forEach(item => {
				item.product.regions.forEach(region => {
					item.product.skipassAgeGroups.forEach(ageGroup => {
						const existingEntry = updatedModel.find(entry => 
							entry.skipassRegion.de === region.key &&
							entry.skipassAgeGroup.de === ageGroup.key
						)
						if (!existingEntry) {
							updatedModel.push({
								key: `_${Date.now()}_${Math.floor(Math.random() * 1000)}`,
								skipassAgeGroup: { de: ageGroup.key },
								packageTravelAgeGroup: { de: '' },
								skipassRegion: { de: region.key },
							})
						}
					})
				})
			})
			
			console.log('updated skipass age group mapping model', this.model)
		},
		getFilteredModelForItem(item) {
			return this.model.filter(modelItem => 
				item.product.regions.some(region => region.key === modelItem.skipassRegion.de)
			)
		},
		getOptionLabel(key, options) {
			const option = options.find(opt => opt.key === key || opt.key?.de === key)
			return option ? option.label?.de || option.label : ''
		},
		openEditDialog() {
			this.editModel = JSON.parse(JSON.stringify(this.model))
			this.editModel.sort((a, b) => a.skipassRegion.de.localeCompare(b.skipassRegion.de))
			this.$refs.editSkipassAgeGroupMappingDialog.open()
		},
		async handleDialogConfirm() {
			this.model = this.editModel
			await this.upsertSkipassMappings()
		},
		async upsertSkipassMappings() {
			try {
				this.loading = true
				this.modelChanged = false
				const skipassAgeGroupMappings = JSON.parse(JSON.stringify(this.model))
				const clientId = this.$store.state.selectedClient.sys.id
				const payload = {
					clientId,
					skipassAgeGroupMappings
				}
				await this.$httpPost('/packageTravel/skipassAgeGroupMappings', payload)
				this.loading = false
				eventBus.$emit('addToastMessage', this.$t('text.changesSuccessfullyProcessed'), 'success')
				// await this.loadOptions()
			}
			catch (error) {
				console.error(error)
				eventBus.$emit('addToastMessage', this.$t('text.errorProcessingChanges'), 'error')
			}
		},
		checkMappingMissing() {
			const ticketAgeGroups = this.injectedData.packageDetails?.fields?.tickets?.de?.map(ticket => ticket.fields.ageGroup.de) ?? []
			const mappedAgeGroups = new Set(this.model.map(mapping => mapping.packageTravelAgeGroup.de))
			this.mappingMissing = ticketAgeGroups.some(ageGroup => !mappedAgeGroups.has(ageGroup))
		},
		isOptionDisabled(ageGroupOption, mapping) {
			if (ageGroupOption.raw.key.de === mapping.packageTravelAgeGroup.de || !ageGroupOption.raw.key.de) {
				return false
			}
			return this.editModel.some(mappingRow => {
				if(mappingRow.skipassRegion.de === mapping.skipassRegion.de) {
					return mappingRow.packageTravelAgeGroup.de === ageGroupOption.raw.key.de
				}
				return false
			})
		},
		isHidden(innerItem, avRegions) {
			return !avRegions.some(region => region.key === innerItem.item.skipassRegion.de)
		},
		async getRegionsAndAgeGroupsForNewSkipassAddedValue(item, expanded) {
			this.loading = true
			try {
				if (expanded && (!item.fields?.product?.regions?.length || !item.fields?.product?.skipassAgeGroups?.length)) {
					const clientId = this.$store.state.selectedClient.sys.id
					const r = await this.$httpPost(`/packageTravel/skipassAVRegionsAndAgeGroups?clientId=${clientId}`, { skipassProducts: item.fields.product?.skus ?? [] })
					item.fields.product.regions = r.regions
					item.fields.product.skipassAgeGroups = r.skipassAgeGroups
				}
			}
			catch (error) {
				console.error(error)
			}
			finally {
				this.loading = false
			}
		},
		hasMissingMappings(item) {
			const ticketAgeGroups = this.injectedData.packageDetails?.fields?.tickets?.de?.map(ticket => ticket.fields.ageGroup.de) ?? []
			const avRegions = item.product.regions.map(region => region.key)
			
			// Check if all ticket age groups are mapped for each region
			for (const region of avRegions) {
				const mappingsForRegion = this.model.filter(mapping => mapping.skipassRegion.de === region)
				const mappedAgeGroupsForRegion = new Set(mappingsForRegion.map(mapping => mapping.packageTravelAgeGroup.de))
				
				if (ticketAgeGroups.some(ageGroup => !mappedAgeGroupsForRegion.has(ageGroup))) {
					return true // We found at least one region with missing age group mappings from the tickets
				}
			}
			
			return false // No missing mappings
		},
	},
	async mounted() {
		console.log('SkipassAgeGroupMapping mounted', this.injectedData.packageDetails)
		await this.loadOptions()
		this.initialLoad = false
	},
}
</script>

<style scoped>
.contentMissing { border: 2px solid orange; }
.subtitle { 
	font-size: 13px; color: grey;
	display: inline-block;
	padding-right: 3px;
}
.expandable-section {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

.filled-warning { 
	padding: 5px 5px;
	background-color: #FFB400;
	color: white;
}
.expanded-content {
	display: flex;
	gap: 20px;
	padding-bottom: 20px;
}

.animate-scale {
	animation: scaleAnimation 1s infinite;
}

.mapping-missing-warning {
	border: 2px solid orange;
}

.group-header {
	font-weight: bold;
	color: #808080;
}

.region-divider {
	border-top: 1px solid #ccc;
	margin-top: 10px;
	padding-top: 10px;
	width: 100%;
}

.column-divider {
	border-right: 1px solid #ccc;
	margin-right: 10px;
	padding-right: 10px;
}

.column-divider:last-child {
	border-right: none;
	margin-right: 0;
	padding-right: 0;
}

.gapped { display: flex; flex-direction: column; gap: 16px; }

.warning-help-text { font-size: 12px; color: orange; }

</style>