<template>
	<!-- <Field :title="$t('text.roundingSettings')" :infoText="$t('text.roundingSettingsHelp')" v-model="model"> -->
	<Field :title="$t('text.roundingSettings')" :infoText="$t('text.roundingSettingsInfo')" >
		<AddButton @click="openRoundingDialog" :update="true" />
		<div style="display: flex; gap: 16px;">
			<div style="flex: 1; display: flex; gap: 16px;">
				<div style="flex: 1; display: flex; gap: 16px; font-weight: bold; color: #666;">
					{{ $t('text.roundingPrecision') }}:
				</div>
				<div style="flex: 1; display: flex; gap: 16px;">
					{{ roundingPrecision?.de }}
				</div>
			</div>
			<div style="flex: 1; display: flex; gap: 16px;">
				<div style="flex: 1; display: flex; gap: 16px; font-weight: bold; color: #666;">
					{{ $t('text.roundingMode') }}:
				</div>
				<div style="flex: 1; display: flex; gap: 16px;">
					{{ roundingStrategies?.find?.(strategy => strategy.id == roundingMode?.de)?.label }}
				</div>
			</div>
		</div>

		<DialogV2 ref="roundingDialog"
			:title="$t('text.roundingDialog')"
			:onConfirm="storeRoundingSettings"
			:onCancel="cancelRoundingDialog"
			:loading="loading"
		>
			<template #content>
				<div :class="{overlayed: loading}">
					<v-progress-linear v-show="loading" :model-value="roundingProgress" color="green" height="8"></v-progress-linear>
					<FieldSet id="roundingSettings" infoText="roundingSettingsInfo">
						<!-- <v-label>{{$t('text.roundingSettings')}}</v-label>
						<p class="helpText" v-html="$t('text.roundingSettingsInfo')"/>
						<br/> -->
						<Field typeName="AddedValue" fieldName="roundingPrecision" v-model="roundingPrecision" class="precision" :title="$t('text.roundingPrecision')" :infoText="$t('text.roundingPrecisionInfo')" />
						<Field v-if="roundingStrategies" typeName="AddedValue" fieldName="roundingMode" v-model="roundingMode" :options="roundingStrategies" class="strategy" :title="$t('text.roundingStrategy')" :infoText="$t('text.roundingStrategyInfo')" />
					</FieldSet>
				</div>
			</template>
		</DialogV2>
	</Field>
</template>

<script>
import Alert from '../../../components/common/Alert.vue'
import DialogV2 from '../../../components/common/DialogV2.vue'
import Common from '../../../mixins/Common.vue'
import AddButton from './AddButton.vue'
import FieldSet from './FieldSet.vue'
import eventBus from '@/utils/eventBus.js'

export default {
	mixins: [ Common ],
	components: { DialogV2, FieldSet, AddButton, Alert },
	props: {
		addedValueProducts: Array,
		linkedEntries: Object,
		modelValue: Object,
	},
	data: () => ({
		roundingPrecision: null,
		roundingMode: null,
		roundingProgress: 0,
		roundingStrategies: null,
	}),
	watch: {
		addedValueProducts: {
			deep: true,
			handler(newVal) {
				this.initRoundingSettings()
			},
		},
	},
	methods: {
		async loadRoundingStrategies() {
			this.loading = true
			try {
				const roundingStrategies = await this.$httpGet('/settings/roundingStrategies')
				// we need to add a label for the model driven dropdown to show the translatable text for the keys
				this.roundingStrategies = roundingStrategies.map(o => ({
					id: o.key,
					label: this.$t('text.' + o.key),
				}))
			}
			catch (error) {
				console.error(error)
			}
			this.loading = false
		},
		async storeRoundingSettings() {
			this.loading = true
			try {
				const totalAddedValues = this.addedValueProducts.length
				let completedAddedValues = 0

				for (const addedValue of this.addedValueProducts) {
					let addedValueWithoutPrefix = addedValue.sys.id.split('AV_')[1]
					await this.$httpPut(`/packageTravel/addedValue/${addedValueWithoutPrefix}/round`, {
						clientId: this.$store.state.selectedClient?.sys.id,
						roundingMode: this.roundingMode.de,
						roundingPrecision: this.roundingPrecision.de,
					})
					completedAddedValues++
					this.roundingProgress = Math.floor((completedAddedValues / totalAddedValues) * 100)
				}

				eventBus.$emit('addToastMessage', this.$t('text.changesSuccessfullyProcessed'), 'success')
			}
			catch (error) {
				console.error(error)
				eventBus.$emit('addToastMessage', this.$t('text.errorProcessingChanges'), 'error')
			}
			this.loading = false
		},
		initRoundingSettings() {
			const firstAvId = this.modelValue.fields.addedValues?.de[0]?.sys.id
			const firstAv = this.linkedEntries[firstAvId]
			this.roundingPrecision = { de: firstAv?.fields?.roundingPrecision.de }
			this.roundingMode = { de: firstAv?.fields?.roundingMode.de ?? 'round_always_up' }
		},
		openRoundingDialog() {
			this.roundingProgress = 0
			this.$refs.roundingDialog?.open?.()
		},
		cancelRoundingDialog() {
			this.$refs.roundingDialog?.close?.()
			this.initRoundingSettings()
		},
	},
	async mounted() {
		await this.loadRoundingStrategies()
		this.initRoundingSettings()
	},
}
</script>

<style scoped lang="scss">
.overlayed {
	pointer-events: none;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 99;
	}
}
</style>