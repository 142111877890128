<template>
	<div>
		<FieldSet id="packageTickets" infoText="ticketsHelp" required>
			<div v-if="pricingType === 'sum'" class="info">
				{{ $t('text.pricingTypeSumHelp') }}
			</div>
			<div v-if="pricingType === 'fixed'" class="info">
				{{ $t('text.pricingTypeFixedHelp') }}
			</div>
			<AddButton @click="addTicket" buttonText="ticket" dataCy="add-ticket" />
			<!-- TODO: the items dont have proper structures, they look like this:
					{ "sys": { "id": "1" }, "title": "Toddler & Child (0 - 6)", "ageRestriction": "2006 - 2008", "duration": "1 text.year", "price": "15.00 CHF" }
					should have 'fields', should have i18n!
				when this is fixed, we should spec the duration col like so:
					{ id: 'duration', type: 'field', fieldName: 'duration' },
			-->
			<SectionTable
				hideFooter
				:items="modelValue"
				typeName="Ticket"
				:columns="[
					{ id: 'type', type: 'slot', fieldName: 'ageGroup' },
					{ id: 'duration', type: 'slot' },
					{ id: 'price', type: 'slot', width: '10%', headerInfo: 'priceHeaderInfo' },
					{ id: '', type: 'options', width: '0%' },
				]"
				no-data-text="noTicketsAdded"
				:options="[
					{ label: $t('text.delete'), icon: 'mdi-delete', action: 'delete' },
				]"
				@option-delete="deleteTicket"
			>
				<template #column-type="{ item }">
					<AgeGroupField v-model="item.fields.ageGroup" />
				</template>
				<template #column-duration="{ item }">
					<DurationField v-model="item.fields.duration" />
				</template>
				<template #column-price="{ item }">
					<div class="d-flex align-center">
						<template v-if="pricingType === 'sum'">
							<div class="mr-1 d-flex column align-center">
								<v-icon>mdi-sigma</v-icon>
								<p class="small-text">{{ $t('text.sumOfOffers') }}</p>
							</div>
							+
						</template>
						<Field class="ml-1" typeName="Ticket" fieldName="price" v-model="item.fields.price" :showTitle="false" />
					</div>
				</template>
			</SectionTable>
			<FlushPeakCache />
		</FieldSet>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import SectionTable from './SectionTable.vue'
import AddButton from './AddButton.vue'
import FieldSet from './FieldSet.vue'
import RadioSelect from '../../../components/common/RadioSelect.vue'
import Field from '../../../components/fields/Field.vue'
import FieldWrap from '../../../components/common/FieldWrap.vue'
import EditableTable from './EditableTable.vue'
import AgeGroupField from '../../../components/fields/AgeGroupField.vue'
import DurationField from '../../../components/fields/DurationField.vue'
import ChildErrorDispatcher from './ChildErrorDispatcher.vue'
import FlushPeakCache from './FlushPeakCache.vue'

export default {
	name: 'TicketsInfo',
	components: { SectionTable, AddButton, FieldSet, RadioSelect, Field, FieldWrap, EditableTable, AgeGroupField, DurationField, FlushPeakCache },
	mixins: [ Common, ChildErrorDispatcher ],
	inject: [ 'injectedData' ],
	props: {
		modelValue: Array,
		pricingType: String,
		linkedEntries: Array,
	},
	computed: {
		currency() {
			return this.$store.state.selectedClient?.fields?.currency?.de || '€'
		},
	},
	watch: {
		modelValue: {
			deep: true,
			handler(n) {
				this.validate()
			},
		},
	},
	methods: {
		newTicket() {
			return {
				sys: {
					id: '',
					type: 'Entry',
					contentType: { sys: { id: 'ticket', type: 'Link', linkType: 'ContentType' } },
				},
				fields: {
					name: { de: '' },
					ageGroup: { de: this.getDefaultAgeGroup() },
					duration: { de: '' },
					price: { de: 0 },
				},
			}
		},
		addTicket() {
			const ticket = this.newTicket()
// TODO: adding the first ticket does not update the ui.. forceUpdate also did not work..
			this.modelValue.push(ticket)
			// TODO: scroll new object into view
		},
		getDefaultAgeGroup() {
			if (this.isGeneralPackage) {
				return ''
			}
			return 'default'
		},
		async deleteTicket(ticket) {
			if (!confirm(this.$t('text.confirmDelete'))) return
			if (ticket.fields.productId?.de) {
				try {
					const r = await this.$httpDelete(`/packageTravel/packages/${ this.injectedData.packageId }/tickets/${ ticket.fields.productId.de }?clientId=${ this.injectedData.clientId }`)
				}
				catch (error) {
					console.error(error)
					alert('cannot delete')
					return
				}
			}
			const index = this.modelValue.findIndex((t) => t.sys.id === ticket.sys.id)
			this.modelValue.splice(index, 1)
		},
/*
		editTicket(id) {
			// TODO: if we get the ticket as a prop then we can emit to the parent component otherwise we can implement the editTicket method here
			// this.$emit('editTicket', id)
			this.ticket = this.modelValue.find((ticket) => ticket.sys.id === id)
			console.log('edit', this.ticket)
			this.$refs.addTicketDialog.open()
		},
		upsertTicket() {
			if (this.ticket.sys.id !== '') {
				this.updateTicket()
			} else {
				this.createTicket()
			}
		},
		async createTicket() {
			// TODO: server method
			this.modelValue.unshift(this.ticket)
			// TODO: for now we generate a temporary id to avoid double addition. How will we handle the id-s in the future?
			this.ticket.sys.id = Math.random().toString(36).substr(2, 9)
			console.log('this.modelValue', this.modelValue)
		},
		async updateTicket() {
			// TODO: server method
			const index = this.modelValue.findIndex(
				(ticket) => ticket.sys.id === this.ticket.sys.id
			)
			this.modelValue[index] = this.ticket
		},
*/
		formatPrice(price) {
			return `${ Number(price).toFixed(2) } ${ this.currency }`
		},
		showDuration(duration) {
			const selectedDuration = this.durationItems.find(
				(item) => item.value === duration
			)
		},
		validate() {
			// for some reason we need to do it on nextTick for the step to become red.
			// probably the errors are being cleared right after this runs
			this.$nextTick(() => {
				if (!this.modelValue?.length)
					this.childErrorAutoDispatch(this.$el, [ { id: 'noTickets' } ])
				else
					this.childErrorAutoDispatch(this.$el, [])
			})
		},
	},
	mounted() {
		this.validate()
	},
}
</script>

<style scoped>
.info { background: aliceblue; border: 1px solid lightblue; border-radius: 8px; padding: 8px 16px; }
.small-text {
	font-size: 10px;
	white-space: nowrap;
}
</style>