<template>
	<div :class="{ disabled }">
		<PersonalisationsDialog ref="dialogRef" v-model="model" />
		<Field :title="$t('text.personalisations')" :infoText="$t('text.personalisationsInfoText')" v-model="model">
		<div @click="() => $refs.dialogRef.open()" class="input">
			<v-chip v-for="item in Object.keys(model)" :key="`chip-${item}`">
				{{ $t(`text.${camelCase(item)}Personalisation`) }}
			</v-chip>
		</div>
		</Field>
	</div>
</template>

<script>
import camelCase from 'lodash/camelCase'
import Field from '@/components/fields/Field.vue'
import PersonalisationsDialog from './PersonalisationsDialog.vue';

export default {
	name: 'PersonalisationsInput',
	components: { Field, PersonalisationsDialog },
	emits: ['update:modelValue'],
	props: {
		modelValue: String,
		disabled: Boolean,
	},
	data() {
		return {
			model: {},
		}
	},
	watch: {
		modelValue: {
			handler: function (value) {
				if (!value?.length) return

				this.model = value.split(',').reduce((acc, field) => {
					acc[field] = true
					return acc
				}, {});
			},
			immediate: true,
		},
		model(value) {
			this.$emit('update:modelValue', Object.keys(value).filter(key => value[key]).join(','))
		},
	},
	setup() {
		return {
			camelCase,
		}
	},
}
</script>

<style scoped lang="scss">
.input {
	cursor: pointer;
	height: auto;
	max-height: initial;
	min-height: 40px;
	padding: 5px 4px;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}
</style>
