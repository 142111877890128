<template>
	<FieldSet id="additionalSettings" infoText="additionalSettingsInfo" required >
		<!-- TODO: remove the add button and age group dropdown when we have a final UX confirmation -->
		<!-- <AddButton @click="addAdditionalSettings" buttonText="additionalSettings" dataCy="add-additional-settings"/> -->
		<SectionTable
			hideFooter
			:items="model"
			typeName="PersonAmountPerAgeGroup"
			:columns="[
				{ id: 'type', type: 'slot', fieldName: 'ageGroup' },
				{ id: 'min', type: 'field', fieldName: 'min', headerInfo: 'personAmountPerAgeGroupHeaderInfo' },
				{ id: 'max', type: 'field', fieldName: 'max', headerInfo: 'personAmountPerAgeGroupHeaderInfo' },
			]"
			no-data-text="noData"
			dataCy="additional-settings"
		>
			<template #column-type="{ item }">
				<!-- <AgeGroupField v-model="item.fields.ageGroup" :editable="false" :showDetails="false" /> -->
				{{ getAgeGroupLabel(item.fields.ageGroup.de) }}
			</template>
		</SectionTable>
	</FieldSet>
</template>

<script lang="ts">
import Common from '@/mixins/Common.vue'
import EditableTable from './EditableTable.vue'
import AddButton from './AddButton.vue'
import FieldSet from './FieldSet.vue'
import FieldWrap from '../../../components/common/FieldWrap.vue'
import Field from '../../../components/fields/Field.vue'
import SectionTable from './SectionTable.vue'
import AgeGroupField from '../../../components/fields/AgeGroupField.vue'
import { load } from '../../../components/fields/loader.ts'

export async function loadDimensions(_this: any, clientId): Promise<{
		duration: { options: any[] }
		ageGroup: { options: any[] }
		skipassAgeGroupMapping: { options: any[], model: any[] }
	}> {
	return await load('DIMS-' + clientId, async () => {
		try {
			const r = await _this.$httpGet(`/packageTravel/dimensions?clientId=${clientId}`)
			r.ageGroup.options.sort((a, b) => (a.label[_this.serviceLocale] ?? '').localeCompare(b.label[_this.serviceLocale]))
			r.duration.options.sort((a, b) => (a.label[_this.serviceLocale] ?? '').localeCompare(b.label[_this.serviceLocale]))
			return r
		}
		catch (error) {
			console.error(error)
		}
	})
}

export default {
	name: 'AdditionalSettings',
	components: { EditableTable, FieldSet, AddButton, FieldWrap, Field, SectionTable, AgeGroupField },
	mixins: [ Common ],
	inject: [ 'injectedData' ],
	props: {
		modelValue: Array,
	},
	data() {
		return {
			model: [],
			additionalSetting: {},
			ageGroupOptions: [],
		}
	},
	watch: {
		'injectedData.packageDetails.fields.tickets.de': {
			deep: true,
			handler(newVal) {
				this.$nextTick(() => {
					// Use nextTick to ensure we're working with the latest data
					const updatedTickets = this.injectedData.packageDetails.fields.tickets.de
					console.log('Updated tickets in nextTick:', updatedTickets)
					this.updateAdditionalSettingsFromTickets(updatedTickets)
				})
			}
		}
	},
	methods: {
		additionalSettingsFactory() {
			return {
				sys: {
					id: 'id_' + Math.random().toString(36).substr(2, 9),
				},
				fields: {
					ageGroup: { de: '' },
					min: { de: 0 },
					max: { de: 0 },
				}
			}
		},
		addAdditionalSettings() {
			this.additionalSetting = this.additionalSettingsFactory()
			this.modelValue.push(this.additionalSetting)
		},
		confirmDialog() {
			this.upsertAdditionalSettings()
		},
		upsertAdditionalSettings() {
			if (this.additionalSetting.sys.id) {
				const index = this.modelValue.findIndex((item) => item.sys.id === this.additionalSetting.sys.id)
				this.modelValue.splice(index, 1, this.additionalSetting)
			}
			else {
				this.additionalSetting.sys.id = this.model.length + 1
				this.modelValue.push(this.additionalSetting)
			}
		},
		updateAdditionalSettingsFromTickets(tickets) {
			this.model = tickets.map(ticket => {
				const existingSetting = this.modelValue.find(setting => setting.fields.ageGroup.de === ticket.fields.ageGroup.de)
				return {
					sys: {
						id: existingSetting ? existingSetting.sys.id : `PAPAG-${ticket.fields.ageGroup.de}`,
						content_type: {
							sys: {
								id: "PersonAmountPerAgeGroup",
								type: "Link",
								linkType: "ContentType"
							}
						}
					},
					fields: {
						ageGroup: { de: ticket.fields.ageGroup.de },
						min: { de: existingSetting ? existingSetting.fields.min.de : 0 },
						max: { de: existingSetting ? existingSetting.fields.max.de : 0 },
					}
				}
			})
			this.$emit('update:modelValue', this.model)
		},
		initializeAdditionalSettings() {
			this.updateAdditionalSettingsFromTickets(this.injectedData.packageDetails.fields.tickets.de)
		},
		async loadOptions() {
			this.loading = true
			const clientId = this.$store.state.selectedClient.sys.id
			const r = await loadDimensions(this, clientId)
			if (r) {
				this.ageGroupOptions = r.ageGroup.options
				// this.initializeAdditionalSettings()
			}
			this.loading = false
		},
		getAgeGroupLabel(key) {
			return this.ageGroupOptions.find(option => option.key.de === key)?.label?.de ?? key
		}
	},
	async mounted() {
		await this.loadOptions()
		this.model = this.injectedData.packageDetails.fields.personAmountPerAgeGroup.de
		console.log('--------------- mounted', this.model)
		this.$emit('update:modelValue', this.model)
	}
}
</script>