<template>
	<div>
		<ServerDataDialog ref="dialogRef"
			v-model="model"
			:title="title ?? $t('text.addedValues')"
			:onConfirm="upsertAddedValues"
			width="70vw"
			height="95vh"
		>
			<template #title>
				<v-toolbar-title>
					<span>{{ $t('text.editAddedValue') }}</span>
				</v-toolbar-title>
			</template>

			<template #content="{ model }">
				<v-progress-linear class="addedValueProgress" v-show="loading" :model-value="addedValuesProgress" color="green" height="10"></v-progress-linear>
				<DisclosureItems :modelValue="model" ref="disclosureItems" :class="{overlayed: loading}">
					<template #itemHead="{ item, expanded }">
						<div class="expansionPanelHeader">
							<div><v-icon :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" />{{ item.fields.name[serviceLocale] }}</div>
							<AddedValueType :isRailawayPackage="isRailawayPackage" :skus="item.fields?.sku?.de" v-model="selectedAvType" />
						</div>
					</template>
					
					<template #itemBody="{ item }" >
						<TransportationAddedValuesForm v-if="transportationView" :modelValue="item" />
						<AddedValueForm v-else :modelValue="item" :selectedAvType="item?.fields?.type?.de" :isRailawayPackage="isRailawayPackage" />
					</template>
				</DisclosureItems>
			</template>
		</ServerDataDialog>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import FieldSet from '../FieldSet.vue'
import Field from '../../../../components/fields/Field.vue'
import MysSwitch from '../../../../components/mys/mys-switch.vue'
import Optional from '../Optional.vue'
import OptionalSettings from '../OptionalSettings.vue'
import AddedValueType from '../AddedValueType.vue'
import Common from '@/mixins/Common.vue'
import DisclosureItems from './DisclosureItems.vue'
import DataDialog from '../../../../components/common/DataDialog.vue'
import Table from '../../../../components/common/tables/Table.vue'
import AddedValueForm from '../dialogs/AddedValueForm.vue'
import TransportationAddedValuesForm from '../dialogs/TransportationAddedValuesForm.vue'
import ServerDataDialog from '../../../../components/common/ServerDataDialog.vue'
import AddedValuesMixin from '@/mixins/AddedValues.vue'
import eventBus from '@/utils/eventBus.js'

export default {
	components: { FieldSet, Field, MysSwitch, Optional, OptionalSettings, AddedValueType, DisclosureItems, DataDialog, Table, AddedValueForm, TransportationAddedValuesForm, ServerDataDialog },
	mixins: [ Common, AddedValuesMixin ],
	inject: ['injectedData'],
	emits: ['update:linkedEntries'],
	props: {
		modelValue: Object,
		transportationView: { type: Boolean, default: false },
		title: String,
		linkedEntries: Object,
		pricingType: String,
	},
	data: () => ({
		model: null,
		copyToReturnJourney: false,
		addedValuesProgress: 0,
		optionalSettingsMap: {},
		selectedAvType: null,
	}),
	computed: {
		isRailawayPackage() {
			return this.injectedData?.packageDetails?.fields?.packageType?.de === 'package-ov' || this.injectedData?.packageDetails?.fields?.packageType?.de === 'package-ov-skipass'
		},
	},
	watch: {
		model(n) { this.$emit('update:modelValue', n) },
		modelValue(n) {
			this.model = n
			// default selectedAvType to the first added value type
			if (!this.selectedAvType && n[0]?.fields?.type?.de) this.selectedAvType = n[0]?.fields?.type?.de
		},
		selectedAvType(avType) {
			this.defaultAddedValueFieldsPerType(avType)
		},
	},
	methods: {
		defaultAddedValueFieldsPerType(avType) {
			if (!this.isRailawayPackage) {
				this.model = this.model.map(addedValue => ({
					...addedValue,
					fields: {
						...addedValue?.fields,
						...this.mapAddedValueFields(avType),
					},
				}));
			} else {
				// For Railway packages, we don't apply the mapping
				// We keep the model as is, preserving both added values if present
			}
		},
		open() {
			this.$refs.dialogRef.open()
			this.resetMeta()
		},
		resetMeta() {
			this.addedValuesProgress = 0
		},
		async upsertAddedValues() {
			const addedValues = this.model
			const totalAddedValues = addedValues.length
			let completedAddedValues = 0
			for (const addedValue of addedValues) {
				try {
					await this.upsertAddedValue(addedValue)
					completedAddedValues++
					this.addedValuesProgress = Math.floor((completedAddedValues / totalAddedValues) * 100)
				}
				catch (error) {
					console.error('Failed to create added value:', error)
					continue
				}
			}

			// update the linkedEntries cache
			console.log('------- linkedEntries', this.linkedEntries)
			const updatedLinkedEntries = cloneDeep(this.linkedEntries)
			console.log('------- updatedLinkedEntries', updatedLinkedEntries)
			console.log('------- addedValues', addedValues)
			addedValues.forEach(addedValue => (updatedLinkedEntries[addedValue.sys.id] = addedValue))

			this.$emit('update:linkedEntries', updatedLinkedEntries)

			if (completedAddedValues === totalAddedValues) {
				console.log('All added values created successfully')
				// TODO: translated success message
				eventBus.$emit('addToastMessage', this.$t('text.changesSuccessfullyProcessed'), 'success')
			}
			else {
				console.log('Some added values failed to create')
				eventBus.$emit('addToastMessage', this.$t('text.errorProcessingChanges'), 'error')
			}

			this.$emit('update:modelValue', addedValues)
		},
		async upsertAddedValue(addedValue) {
			this.showLoader(true)
			const payload = {
				clientId: this.$store.state.selectedClient.sys.id,
				addedValue: addedValue,
			}
			const packageId = this.$route.params.id
			const addedValueId = addedValue.sys.id.replace('AV_', '')
			const r = await this.$httpPut(`/packageTravel/package/${packageId}/addedValue/${addedValueId}`, payload)
			this.showLoader(false)
		},
		showLoader(value = false) {
			this.loading = value
		},
	},
	async mounted() {
		this.model = this.modelValue
	},
};
</script>

<style scoped lang="scss">
.sub-title {
	line-height: normal;
	color: #b1b1b0;
}

.content {
	p {
		font-size: 12px;
		line-height: 16px;
		color: black;
	}

	.title {
		font-size: 17px;
		line-height: 22px;
		font-weight: bold;
	}
}

.tableCard {
	margin-top: 24px;
}

.expandable-section {
	margin-top: 24px;
}

.section-body {
	margin-left: 12px;
	margin-right: 12px;
}

.days {
	display: flex;
	justify-content: space-between;
	// width: 50%;
	flex-grow: 1;
	padding: 12px;
}
.column {
	width: 50%;
}
.title {
	font-size: 12px;
	line-height: 22px;
	font-weight: bold;
}
.subtitle {
	font-size: 12px;
	line-height: 16px;
	color: #b1b1b0;
	padding: 12px 0;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
}
.sidebar-options {
	padding: 12px;
	border-bottom: 1px solid #e0e0e0;
	cursor: pointer;
}
.sidebar-options:hover {
	background-color: #0ba7e1;
	color: white;
	// border: none;
}
.highlighted {
	background-color: #0ba7e1;
	color: white;
	// border: none;
}
.panel-header {
	background-color: #f4f4f4;
}

.expandIcon {
	display: flex;
	align-items: center;
	justify-content: left;
	width: calc(18vw + 400px);
	height: 24px;
	border-radius: 50%;
	background-color: #f4f4f4;
}

.sectionTitle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 24px;
	font-size: 14px;
	font-weight: bold;
}

@media (min-width: 1900px) {
	.sectionTitle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80%;
		height: 24px;
	}
}

.sectionTitleEnd {
	display: flex;
	align-items: center;
	justify-content: end;
	width: 30%;
	height: 24px;
}

.optional-items {
	// TODO: this is breaking the left border and each optional item now has its own border. Is that fine?
	& > * {
		padding-bottom: 20px;
	}
}

.expansionPanelHeader {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.addedValueProgress {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
}

.overlayed {
	pointer-events: none;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 99;
	}
}

</style>
