<template>
	<div class="SectionTable">
		<v-data-table v-if="items?.length"
			:headers="headers"
			:items="itemWrappers"
			:no-data-text="$t('text.' + noDataText)"
			:items-per-page="hideFooter ? 9999 : limit"
			v-model:expanded="expanded"
			:item-value="item => item"
		>
			<template #headers>
				<tr>
					<th
						class="v-data-table__td v-data-table-column--align-start v-data-table__th"
						v-for="header in headers"
						:key="header.id"
						:style="`width: ${header.width || '20%'};`"
					>
						<div class="v-data-table-header__content">
							<span v-if="$te(`text.${header.id}`)">{{ $t(`text.${header.id}`) }}</span>

							<v-tooltip location="bottom" v-if="header.headerInfo">
								<template #activator="{ props }">
									<v-icon v-bind="props" color="grey" size="small" class="ml-2">mdi-information</v-icon>
								</template>
								<div style="max-width: 255px;">
									<p v-html="$t(`text.${header.headerInfo}`)" />
								</div>
							</v-tooltip>
						</div>
					</th>
				</tr>
			</template>

			<template #item="{ item, index }">
				<!-- NOTE we use item.item and item.fields because we wrap each item in itemWrappers
						we do this to have unified access to fields -->
				<slot name="warning" :item="item.item" :index="index" />
				<tr
					class="itemRow"
					:class="{
						'error-border': item.addl && item.addl.valid == false,
						'hidden': hiddenCheck(item),
						'clickable': isClickable,
					}"
					@click="$emit('click-item', item.item, index)"
					:data-cy="dataCy + `-table-row`"
				>
					<td v-for="(column, c) in headers" :key="c" class="centered-cell">
						<template v-if="column.type == 'field'">
							<div class="row">
								<v-icon v-if="column.mdiIcon">{{ 'mdi-' + column.mdiIcon }}</v-icon>
								<Field :typeName="column.typeName" :fieldName="column.fieldName" v-model="item.fields[column.key]" :showTitle="false" />
							</div>
						</template>
						<template v-if="column.type == 'date'">
							{{ formatDate(item.fields[column.key]) }}
						</template>
						<template v-else-if="column.type == 'string' || column.type == 'number'">
							{{ item.fields[column.key]?.de ?? item.fields[column.key] }}
						</template>
						<template v-else-if="column.type == 'chip'">
							<div style="display: flex; flex-wrap: wrap; gap: 5px; justify-content: left;">
								<v-chip v-for="value in arrayFor(item.fields[column.key])" :key="value"
									color="grey" text-color="black"
								>
									{{ value.trim() }}
								</v-chip>
							</div>
						</template>
						<template v-else-if="column.type == 'options'">
							<PopoverMenu
								:items="options"
								@click="(action) => $emit('option-' + action, item.item, index)"
								:dataCy="dataCy"
							/>
						</template>
						<template v-else-if="column.type == 'warning'">
							<div>
								<slot name="warning-row" :item="item.item" :index="index" />
							</div>
						</template>
						<template v-else-if="column.type == 'expand'">
							<div class="expandable-section">
								<slot name="content-warning" :item="item.item" :index="index" />
								<v-btn v-if="column.canEdit" variant="text" icon @click="$emit('option-edit', item.item, index)" :disabled="column.disabled">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
								<v-btn v-if="column.canAdd" variant="text" icon @click="$emit('option-add', item.item, index)" :disabled="column.disabled">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
								<v-btn :icon="expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down'" variant="text" @click="expand(item, index)" :disabled="column.disabled">
								</v-btn>
							</div>
						</template>
						<template v-else-if="column.type == 'slot'">
							<slot :name="'column-' + column.id" :item="item.item" :column="column" :index="index" />
						</template>
					</td>
				</tr>
				<tr v-if="$slots['row-divider']">
					<td :colspan="headers.length">
						<slot name="row-divider" :item="item.item" :index="index" />
					</td>
				</tr>
			</template>
			<template v-slot:expanded-row="{ columns, item, index }">
				<tr>
					<td :colspan="columns.length">
						<slot name="expanded-row" :item="item.item" :index="index" />	
					</td>
				</tr>
			</template>
			<template #bottom>
				<TableFooter v-if="!hideFooter"
					:offset="offset"
					:limit="limit" :results="items"
					:total="total"
					@update:offset="$emit('update:offset', $event)"
					@update:limit="$emit('update:limit', $event)"
				/>
			</template>
		</v-data-table>
		<div v-if="!items?.length">{{ $t('text.' + noDataText) }}</div>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import PopoverMenu from '../../../components/common/PopoverMenu.vue'
import TableFooter from '../../../components/common/tables/TableFooter.vue'
import Field from '../../../components/fields/Field.vue'

export default {
	name: 'SectionTable',
	mixins: [ Common ],
	components: { PopoverMenu, TableFooter, Field },
	props: {
		columns: { type: Array, required: true },
		items: { type: Array, required: true },
		noDataText: { type: String, default: 'noData' },
		hideFooter: { type: Boolean, default: true },
		options: { type: Array, default: () => [] },
		typeName: String,
		mdiIcon: String,
		showExpand: { type: Boolean, default: false },
		dataCy: { type: String, default: 'section-table'},
		hiddenCheck: { type: Function, default: () => false },
	},
	data: () => ({
		limit: 150,
		offset: 0,
		expanded: [],
	}),
	computed: {
		isClickable() {
			return this.$attrs?.onClickItem !== undefined
		},
		itemWrappers() {
			return this.items.sort((a, b) => {
				const sortOrderA = a.sortOrder || 0;
				const sortOrderB = b.sortOrder || 0;
				return sortOrderA - sortOrderB;
			}).map(item => ({
				fields: item.fields ?? item,
				item,
				addl: item.addl,
			}))
		},
		headers() {
			return this.columns.map(column => {
				if (typeof column == 'string') column = { id: column }
				return {
					...column,
					title: column.title ? this.$t('text.' + column.title)
						: column?.id !== '' ? this.$t('text.' + column.id)
						: '',
					key: column.id ?? column,
					width: column.width ?? '20%',
					sortable: false, //column?.id == '' ? false : true,
					cellClass: column.id ?? column,
					type: column.type ?? 'string',
					typeName: column.typeName ?? this.typeName,
				}
			})
		},
		total() {
			return this.items.length
		},
	},
	methods: {
		arrayFor(value) {
			if (value?.de) value = value.de
			if (Array.isArray(value)) return value
			if (typeof value == 'string') return value.split(',')
			return []
		},
		expand(item, index) {
			this.$emit('expand-row', item, !this.expanded.includes(item), index)
			if (this.expanded.includes(item)) {
				const index = this.expanded.indexOf(item)
				this.expanded.splice(index, 1)
				return
			}
			this.expanded.push(item)
		},
	},
}
</script>

<style scoped>
.SectionTable { font-size: 15px; }
.itemRow { padding: 10px 10px; border-radius: 4px; width: 100%; align-items: center; cursor: default; }
.itemRow:hover { background-color: #f2f2f2; }
.SectionTable { width: 100%; padding: 10px 10px; }
.SectionTable th { text-align: left; padding: 15px 10px; /* border-bottom: 1px solid #ccc; */ }
.SectionTable td { padding: 15px 10px; vertical-align: middle; }
.dotsIcon:hover { transform: scale(1.2); }
.optionItem:hover { cursor: pointer !important; }
.clickable { cursor: pointer; }

.v-data-table { margin-left: -10px; }
::v-deep .v-data-table th,
::v-deep .v-data-table td { font-size: inherit !important; padding-left: 10px !important; padding-right: 0 !important; border: 0 !important; }
::v-deep .v-data-table th { padding-bottom: 10px !important; }

/* ::v-deep .v-data-table-header {
	font-weight: bold !important;
	color: black !important;
} */
/* .v-data-table > table > tbody > tr > td {
	border-bottom: none !important;
	border-collapse: collapse !important;
} */

/* TODO: overwrite default table headers to have bold and black text */
::v-deep .v-data-table-header__content {
	font-weight: bold !important;
	color: black !important;
}

.row {
	display: flex;
	align-items: center;
}

.error-border { border: 5px solid red }

.expandable-section {
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 20px;
}

.filled-warning { 
	padding: 5px 5px;
	background-color: #FFB400;
	color: white;
}

.hidden {
	display: none;
}

.centered-cell {
	vertical-align: middle;
}
</style>