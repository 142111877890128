<template>
	<Step id="content" icon="mdi-fountain-pen-tip" :forceStatus="modelValue?.fields?.contentMode?.de == 'ticket' ? 'complete' : undefined">
		<Section id="general" open :disabled="ticketOnly">
			<Field typeName="Package" fieldName="contentMode" v-model="modelValue.fields.contentMode" />
		</Section>
		<Section id="publicInfo" :open="!ticketOnly" :disabled="ticketOnly">
			<Field v-if="modelValue.fields.contentMode?.de == 'product'" typeName="Package" fieldName="longDescription" v-model="modelValue.fields.longDescription" />
			<Field v-if="modelValue.fields.contentMode?.de == 'product'" typeName="Package" fieldName="shortDescription" v-model="modelValue.fields.shortDescription" />
			<!-- TODO: make sure this is in the model -->
			<!-- <Field typeName="Package" fieldName="medias" v-model="modelValue.fields.medias.de" :disabled="modelValue.fields.contentMode === 'ticket'" /> -->
		</Section>
		<Section v-if="isGeneralPackage" id="productSpecific" open>
			<FieldSet id="productDetails" infoText="productDetailsHelp">
				<ProductContentsTable v-if="products"
					:modelValue="modelValue"
					:products="products"
					:linkedEntries="linkedEntries"
				/>
			</FieldSet>
		</Section>
		<Section id="categories" :open="!ticketOnly" :disabled="ticketOnly">
			<div class="field left-border" v-if="modelValue.fields.contentMode?.de == 'product'">
				<v-label>{{ $t('text.productCategories') }}</v-label>
				<p class="helpText">{{ $t('text.productCategoriesHelpText') }}</p>
				<v-select
					:placeholder="`${$t('text.chooseProductCategories')}...`"
					variant="outlined"
					density="compact"
					:modelValue="modelValue.fields.productCategories"
					:items="productCategoryOptions"
					multiple
					chips
					hide-details
					:disabled="ticketOnly"
				>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ $t('text.' + item.id) }}</v-chip>
					</template>
				</v-select>
			</div>
		</Section>
	</Step>
</template>

<script lang="ts">
import Step from './Step.vue'
import Section from './Section.vue'
import FieldSet from './FieldSet.vue'
import Field from '../../../components/fields/Field.vue'
import Common from '../../../mixins/Common.vue'
import SectionTable from './SectionTable.vue'
import IfFeatureFlag from '../../../components/ifFeatureFlag/IfFeatureFlag.vue'
import Dialog from '../../../components/common/Dialog.vue'
import DialogV2 from '../../../components/common/DialogV2.vue'
import LanguageSidebar from '../../../components/common/LanguageSidebar.vue'
import TranslateableField from '../../../components/fields/TranslateableField.vue'
import ImagesField from '../../../components/common/ImagesField.vue'
import BaseProductContentDialog from './BaseProductContentDialog.vue'
import ProductContentsTable from './ProductContentsTable.vue'

export default {
	components: { Section, FieldSet, Field, Step, SectionTable, IfFeatureFlag, Dialog, LanguageSidebar, TranslateableField, DialogV2, ImagesField, BaseProductContentDialog, ProductContentsTable },
	mixins: [ Common ],
	props: {
		modelValue: Object,
		salesChannels: Array,
		products: Array,
		linkedEntries: Object,
	},
	data: () => ({
		loading: false,
		productCategoryOptions: [],
	}),
	computed: {
		ticketOnly() {
			// TODO: once we implement the content step for general packages (PHASE 2), we should change this logic so that the content mode is ONLY disabled for RAW packages
			return this.modelValue.fields.contentMode?.de === 'ticket'
		},
	},
	methods: {
		async getProductCategoryOptions() {
			try {
				this.loading = true
				const { productCategories } = await this.$httpGet(`/packageTravel/productCategories?clientId=${this.$store.state.selectedClient.sys.id}`)
				this.productCategoryOptions = productCategories.map(({ category_id, category_name }) => ({ title: category_name, value: category_name, id: category_id }))
			}
			catch (error: any) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			}
			finally {
				this.loading = false
			}
		},
	},
	mounted() {
		this.getProductCategoryOptions()
	},
}
</script>

<style scoped>
</style>