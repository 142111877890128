<template>
	<Application>
		<template #navbar></template>
		<div class="Playground">
			Comments: <Comments objectId="MA-2ff78ed1-7a50-42f1-8c3d-e61fe9bf7f64" style="padding: 20px; background: white;" />
			<!--
			<ContingentSlot :modelValue="{ time: 'All Day', total: 4, free: 4 }" :day="{ date: '2024-10-10' }" :dates="{ today: '2024-10-10' }" />
			Api2NestTest: <Api2NestTest />
			IsoDatePicker ({{ IsoDatePickerModel }}): <IsoDatePicker v-model="IsoDatePickerModel" />
			IsoDatePicker ({{ IsoDatePickerModel }}): <IsoDatePicker v-model="IsoDatePickerModel" />
			DatePickerCalendar ({{ DatePickerCalendarModel }}): <DatePickerCalendar v-model="DatePickerCalendarModel" />
			PriceMatrixProfileCalendar: <PriceMatrixProfileCalendar style="background: white;" />
			SeatPlanEditor: <SeatPlanEditor />
			TestStepperDialog: <TestStepperDialog />
			TestRmi: <TestRmi />
			TrackingView <TrackingView entryType="product" entryId="MA-2ff78ed1-7a50-42f1-8c3d-e61fe9bf7f64" />
			PeakServiceProviders: <PeakServiceProviders />
			ImageEnhancerPlayground: <ImageEnhancerPlayground />
			BusinessHoursProfileCalendar: <BusinessHoursProfileCalendar style="background: white;" />
			-->
		</div>
	</Application>
</template>

<script>
import TestRmi from './playground/TestRmi.vue'
import ImageEnhancerPlayground from './applications/mediaGallery/ImageEnhancerPlayground.vue'
import PeakServiceProviders from './applications/packageDesigner/PeakServiceProviders.vue'
import Application from './applications/Application.vue'
import BusinessHoursProfileCalendar from './applications/packageDesigner/BusinessHoursProfileCalendar.vue'
import PriceMatrixProfileCalendar from './applications/packageDesigner/PriceMatrixProfileCalendar.vue'
import TrackingView from '../components/common/TrackingView.vue'
import TestStepperDialog from './playground/TestStepperDialog.vue'
import SeatPlanEditor from './playground/SeatPlanEditor.vue'
import Comments from '../components/comments/Comments.vue'
import DatePickerCalendar from './applications/packageDesigner/DatePickerCalendar.vue'
import IsoDatePicker from '../components/businesshours/IsoDatePicker2.vue'
import Api2NestTest from './playground/Api2NestTest.vue'
import ContingentSlot from '../components/serviceDesigner/contingentMonitor/ContingentSlot.vue'

export default {
	components: { TestRmi, PeakServiceProviders, ImageEnhancerPlayground, Application, BusinessHoursProfileCalendar, PriceMatrixProfileCalendar, TrackingView, TestStepperDialog, SeatPlanEditor, Comments, DatePickerCalendar, IsoDatePicker, Api2NestTest, ContingentSlot },
	data: () => ({
		DatePickerCalendarModel: '2024-10-10',
		IsoDatePickerModel: '2024-10-09T22:00:00.000Z',
	}),
}
</script>

<style scoped>
.Playground { padding: 60px 0px; }
.Playground > * { border: 1px solid silver; margin-bottom: 20px; }
</style>