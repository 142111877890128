<template>
	<div>
		<LoadingSpinner v-if="loading" style="margin-bottom: 10px;" data-cy="loading" />
<!-- TODO: flag for "hasDayMenu" - or can we automatically detect if the dayMenu slot is given/empty? -->
<!--
v-if="calendar.length"
-->
		<DataCalendar ref="dataCalendar" v-if="dates.count > 0"
			:dates="dates"
			:calendar="calendar"
			:limit="limit"
			:clickOpensMenu="false"
			:showDayMenu="false"
			navMode="MONTH"
			v-model:offset="offset"
			v-model:extended="extended"
		>
			<template #dayMenu="{ day }">
				<div class="item" v-if="!dayExpanded[day.date] && day.slots.length > 5" @click="dayExpanded[day.date] = true">
					<v-icon>mdi-unfold-more-horizontal</v-icon> expand
				</div>
				<div class="item" v-if="dayExpanded[day.date] && day.slots.length > 5" @click="dayExpanded[day.date] = false">
					<v-icon>mdi-unfold-less-horizontal</v-icon> collapse
				</div>
			</template>
			<template #day="{ day }">
				{{ day.availabilityInfo }}
				<div style="display: flex; flex-direction: column; gap: 4px;">
<!-- TODO: expose the 'selectedDay' as prop? -->
					<ContingentSlot v-for="slot in day.slots" :key="'s' + slot.time + update"
						:modelValue="slot"
						:day="day"
						:dates="dates"
						:dayMenu="false"
						:dayExpanded="dayExpanded"
						:dayVisited="dayVisited"
						@click="selectSlot(day, slot)"
						:class="{
							collapsed: !dayExpanded[day.date] && day.slots.length > 5,
							visited: dayVisited[day.date + slot?.time],
						}"
					>
						<template #menu>
							<v-list>
								<v-list-item @click="showOrders(day, slot)"><v-icon>mdi-eye</v-icon> {{ $t('text.showOrders') }}</v-list-item>
								<!-- TODO: dont allow edit on past slots -->
								<IfFeatureFlag flag="FEATURE_CONTINGENT_EDIT">
									<v-list-item @click="editContingent(day, slot)"><v-icon>mdi-pencil</v-icon> {{ $t('text.editContingent') }}</v-list-item>
									<!--
									<v-list-item><v-icon>mdi-delete</v-icon> Delete Contingent</v-list-item>
									-->
								</IfFeatureFlag>
							</v-list>
						</template>
					</ContingentSlot>
				</div>
			</template>
		</DataCalendar>
<!-- TODO: timeslots navigation in a sidebar? -->
		<DialogV2 ref="ordersDialog" width="80%" height="450"
			:title="$t('text.orders') + ' ' + selectedDay?.date + ' ' + selectedSlot?.time"
		>
			<template #sidebar>
				<div style="padding: 32px; display: flex; flex-direction: column; gap: 16px;">
					{{ $t('text.selectedItem') }}
					<ContingentSlotStandalone :modelValue="selectedSlot" :day="selectedDay" :dates="dates" />
				</div>
			</template>
			<template #content>
				<ContingentOrders v-if="selectedDay" ref="contingentOrders"
					:product="product"
					:date="selectedDay.date"
				/>
				<!-- ??
					:time="selectedTime?.time"
				-->
			</template>
		</DialogV2>
		<ContingentSlotDialog ref="editContingentDialog"
			:client="client"
			:product="product"
			:selectedDay="selectedDay"
			:selectedSlot="selectedSlot"
			:dates="dates"
			:onConfirm="() => update++"
		/>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import Common from '../../../mixins/Common.vue'
import DataCalendar from '../../common/DataCalendar.vue'
import LoadingSpinner from '../../common/LoadingSpinner.vue'
import ContingentOrders from './ContingentOrders.vue'
import Dialog from '../../common/Dialog.vue'
import ContingentSlot from './ContingentSlot.vue'
import ContingentSlotFree from './ContingentSlotFree.vue'
import ContingentSlotDialog from './ContingentSlotDialog.vue'
import ContingentSlotStandalone from './ContingentSlotStandalone.vue'
import DialogV2 from '../../common/DialogV2.vue'
import DataDialog from '../../common/DataDialog.vue'
import IfFeatureFlag from '../../ifFeatureFlag/IfFeatureFlag.vue'

// TODO: extract the dialog component to a separate file (loading, ..)

// TODO: respect feature flag

// TODO: loading indicator
// TODO: i generalized to DataCalendar -> also migrate BHCalendar to DataCalendar
// TODO: are removed availabilities shown in this result?
// TODO: why do from and until dates have different time formats?
// TODO: shouldnt the from and until only be 1 minute apart? they are 2h1m apart.. is this a TZ issue?
/*
res = {
	availabilities: [{
		"from": "2023-06-01T09:00:00.000Z",
		"until": "2023-06-01T07:01:00Z",
		"used": 0,
		"free": 5,
		"total": 5,
		"overbookFree": 0,
		"overbookTotal": 0,
		"overbook2Total": 0
	}],
}
*/
export default {
	name: 'ContingentCalendarNew',
	mixins: [ Common ],
	components: { DataCalendar, LoadingSpinner, ContingentOrders, Dialog, ContingentSlot, ContingentSlotFree, ContingentSlotDialog, ContingentSlotStandalone, DialogV2, DataDialog, IfFeatureFlag },
	props: {
		availabilities: Array,
		product: Object,
	},
	data: () => ({
		limit: 7 * 6,
		offset: 0,
		selectedDay: null,
		selectedSlot: null,
		dayMenu: false,
		timeMenu: false,
		space: null,
		availabilityDays: null,
		dayExpanded: {},
		dayVisited: {},
		extended: false,
		extendedLimit: 7 * 52,
		// TODO: this is a bad hack for an update problem:
		//       by setting update we reset all keys on the slots to force their repaint..
		update: 0,
	}),
	computed: {
		client() {
			return this.$store.state.selectedClient
		},
		dates() {
			// find min and max date
			let minDate = null
			let maxDate = null
			this.availabilities.forEach(ts => {
				const from = ts.fields.validFromDate.de.substring(0, 10)
				if (!minDate || from < minDate) minDate = from
				const to = ts.fields.validToDate.de.substring(0, 10)
				if (!maxDate || to > maxDate) maxDate = to
			})
			const todayDate = moment().startOf('day')
			const today = todayDate.format('YYYY-MM-DD')
			// always include today in the range
			if (today < minDate) minDate = today
			if (today > maxDate) maxDate = today

			let startDate = moment(minDate).startOf('day')
			let fromDate = startDate.clone()
			const toDate = moment(maxDate).endOf('day')
			const fromMonday = fromDate.subtract((fromDate.day() - 1) % 7 + 7, 'days')
			const toSunday = toDate.add(6 - (toDate.day() - 1) % 7 + 7, 'days')
			const count = toSunday.diff(fromMonday, 'days') + 1
			const offsetToday = Math.floor(todayDate.diff(startDate, 'days') / this.limit) * this.limit
			return { today, offsetToday, minDate, maxDate, fromDate, toDate, fromMonday, toSunday, count }
		},
		calendar() {
			if (!this.availabilityDays) return []

			// TODO: into separate computed prop since this doesnt change
			const avLookup = {}
			this.availabilityDays.forEach(d => {
				// TODO: TZ?
				const from = moment(d.from)
				const date = d.from.substr(0, 10) //from.format('YYYY-MM-DD')
				if (!avLookup[date]) avLookup[date] = {}
// TODO: slots are offset 2h - probably we may not use moment, but have to cut the time out of the string..
				const time = d.from.substr(11, 5) //from.format('HH:mm')

// TODO: the old comp calculates like this, but how can this be correct?
//       booked: d.total - d.free - d.overbookFree
//          const bookedTotal = (availability.total - availability.free) + (availability.overbookFree * -1)
				avLookup[date][time] = { ...d, time }
			})

			const days = []
			let d = -1
			for (let day = this.dates.fromMonday.clone(); day <= this.dates.toSunday; day = day.add(1, 'days')) {
				d++
				if (d < this.offset) continue
				if (d >= this.offset + this.limit && !this.extended) continue
				if (d >= this.offset + this.extendedLimit) continue
				const weekday = day.format('dddd').toLowerCase()
				const date = day.format('YYYY-MM-DD')
				const dateObject = day.toDate()
				const year = day.format('YYYY')
				const inRange = date >= this.dates.minDate && date <= this.dates.maxDate
				let open = false

// TODO: add availability info from contingent svc!
				const slots = Object.values(avLookup[date] ?? {})
// TODO: add the exception infos to the day?

// TODO: show day as open if there is an availability for it?

				days.push({ date, open, /*times,*/ inRange, slots, /*exceptionInfo,*/ dateObject, weekday, year })
			}
			return days
		},
	},
	methods: {
		async load() {
			if (this.loading) return
			this.loading = true
			try {
				const res = await this.$httpGet(`/contingents/${ this.client.fields.clientId.de }/${ this.product.sys.id }`)
				this.space = res.space
				this.availabilityDays = res.availabilities
			}
			catch (error) {
				console.log(error)
				// TODO
				//this.showError(error)
			}
			this.loading = false
		},
		selectSlot(day, slot) {
			this.dayVisited[day.date + slot.time] = true
			this.selectedDay = day
			this.selectedSlot = slot
		},
		showOrders(day, slot) {
			this.selectSlot(day, slot)
			this.$refs.ordersDialog.open()
			// TODO: hide daymenu of data calendar somehow..
		},
		editContingent(day, slot) {
			this.selectSlot(day, slot)
			this.$refs.editContingentDialog.open()
		},
	},
	async mounted() {
		await this.load()
	},
}
</script>

<style scoped>
.slot { padding: 1px 4px; margin-bottom: 2px; background: #eee; border-radius: 4px; position: relative; }
.slot.collapsed { display: inline-block; margin-right: 2px; }
.slot.collapsed .time { display: none; }
.slot.collapsed .total { display: none; }
.slot:hover { outline: 2px solid #333; cursor: pointer; }
.slot.visited::after { content: ""; position: absolute; top: 2px; right: 2px; width: 3px; height: 3px; border-radius: 3px; background: violet; }
</style>