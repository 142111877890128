<template>
	<Step id="packageSpecific" icon="mdi-package-variant-closed">
		<Section id="general" open>
			<FieldSet id="availabilities">
				<div style="display: flex; gap: 10px;">
					<Field typeName="Package" fieldName="startDate" v-model="modelValue.fields.startDate" style="flex: 1;" dataCy="start-date" />
					<Field typeName="Package" fieldName="endDate" v-model="modelValue.fields.endDate" style="flex: 1;" dataCy="end-date"
						:min="modelValue.fields.startDate?.de?.substring(0,10) ?? null"
					/>
				</div>
			</FieldSet>
			<FieldSet id="additionalDateRestrictions">
				<!-- TODO: init if prop is missing! -->
				<AvailabilityColorCalendar
					v-if="modelValue.fields.additionalDateRestrictions"
					v-model="modelValue.fields.additionalDateRestrictions.de"
					:min="modelValue.fields.startDate?.de?.substring(0,10) ?? null"
					:max="modelValue.fields.endDate?.de?.substring(0,10) ?? null"
				/>
				<!--<ColorCalendar v-model="model.fields.availabilities" />-->
			</FieldSet>
			<FieldSet id="price" required class="gapped" >
				<!-- TODO: where do we store this field? do we send 2 calls, 1 for product CONTENT and one for product COMMERCE? -->
				<Field typeName="Package" fieldName="pricingType" v-model="modelValue.fields.pricingType" @change="displayUpdatePricingWarningMessage" />
				<!-- TODO: actually this should only show if products have arying tax rate (?)
					what if that these tax rates change later?
					would it not be better to just ask for the tax rate and prefill it from the first product?
				-->
				<Field typeName="Package" fieldName="taxClass" v-model="modelValue.fields.taxClass"
					:options="taxClasses"
				/>
				<RoundingSettings v-if="addedValueProducts?.length || transportationProducts?.length" v-model="model" :addedValueProducts="[ ...addedValueProducts, ...transportationProducts ]" :linkedEntries="linkedEntries" />
			</FieldSet>
			<PersonalisationsInput v-model="modelValue.fields.personalisations.de" :disabled="isRailawayPackage" />
			<Field typeName="Package" fieldName="validityType" v-model="modelValue.fields.validityType" />
		</Section>
		<Section id="miscellaneous" open>
			<Field typeName="Package" fieldName="bookableDaysInAdvance" v-model="modelValue.fields.bookableDaysInAdvance" />
			<FieldSet id="packageVoucher" infoText="packageVoucherHelp" style="padding-bottom: 20px;">
				<Field typeName="Package" fieldName="needsVoucherAndEwallet" v-model="modelValue.fields.needsVoucherAndEwallet" />
			</FieldSet>
		</Section>
	</Step>

	<Dialog ref="updatePricingWarningDialog"
		:title="$t('text.updatePricing')"
		:confirmLabel="$t('text.confirm')"
		:cancelLabel="$t('text.cancel')"
		:cancelHandler="onCancelUpdatePricing"
		width="540px"
		height="350px"
		dataCy="pricing-warning-dialog"
	>
		<template #content>
			<div class="update-pricing-container">
				<v-icon color="warning" size="96px">mdi-alert</v-icon>
				<div class="update-pricing-text">
					<p class="update-pricing-title">{{ $t('text.updatePricingWarningTitle') }}</p>
					<p class="update-pricing-desc">{{ $t('text.updatePricingWarningDesc') }}</p>
				</div>
			</div>
		</template>
	</Dialog>
</template>

<script>
import Step from './Step.vue'
import Section from './Section.vue'
import FieldSet from './FieldSet.vue'
import AvailabilityColorCalendar from './AvailabilityColorCalendar.vue'
import Field from '../../../components/fields/Field.vue'
import AddedValue from './AddedValue.vue'
import Dialog from '@/components/common/Dialog.vue'
import AddButton from '../../../views/applications/packageDesigner/AddButton.vue'
import { field } from '../../../components/fields/FieldMixin.js'
import PersonalisationsInput from './personalisations/PersonalisationsInput.vue'
import RoundingSettings from './RoundingSettings.vue'

export default {
	components: { Section, FieldSet, AvailabilityColorCalendar, Field, AddedValue, Step, Dialog, AddButton, PersonalisationsInput, RoundingSettings },
	mixins: [ field ],
	props: {
		modelValue: Object,
		products: Array,
		linkedEntries: Object
	},
	data: () => ({
		model: null,
		taxClasses: [],
	}),
	computed: {
		isRailawayPackage() {
			return this.model?.fields?.packageType?.de === 'package-ov' || this.model?.fields?.packageType?.de === 'package-ov-skipass'
		},
		addedValueProducts() {
			return this.model?.fields?.addedValues?.de ?? []
		},
		transportationProducts() {
			const transportationProductIds = this.model?.fields?.addedValuesTransportation?.de ?? []
			const transportProducts = transportationProductIds.map(entry => this.linkedEntries[entry.sys.id])
			return transportProducts
		}
	},
	watch: {
		model: {
			deep: true,
			handler(n) { this.$emit('update:modelValue', n) },
		},
		modelValue(n) { this.model = n },
	},
	methods: {
		async getTaxClasses() {
			// TODO: get tax classes from content-manager instead of peak because of localization? PEAK only has the titles in German ??
			// const clientId = this.$store.state.selectedClient.sys.id
			// PEAK tax classes
			// const res = await this.$httpGet(`/packageTravel/taxClasses?clientId=${clientId}`)
			// if we pass an id to the taxClass, the model-driven dropdown component will only use the id as a value, and therefore in the backend we would need to fetch the data again. Atm it is returning the entire json as a value.
			// this.taxClasses = res.taxClasses.map(tc => ({ label: tc.fields.title.de }))
			// MYS tax classes
			this.taxClasses = this.$store.state.selectedClient.fields.taxClasses.de
		},
		displayUpdatePricingWarningMessage() {
			this.$refs.updatePricingWarningDialog.show = true
		},
		onCancelUpdatePricing() {
			this.$refs.updatePricingWarningDialog.show = false
			this.model.fields.pricingType.de = this.model?.fields?.pricingType?.de === 'sum' ? 'fixed' : 'sum'
		},
	},
	async mounted() {
		await this.getTaxClasses()
		this.model = this.modelValue
	}
}
</script>

<style lang="scss" scoped>
.update-pricing-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 48px;
	width: 100%;

	.update-pricing-text {
		display: flex;
		flex-direction: column;
		gap: 8px;
		max-width: 268px;

		.update-pricing-title {
			font-size: 20px;
			font-weight: 600;
			line-height: 1.25;
			letter-spacing: -0.98px;
			color: black;
		}

		.update-pricing-desc {
			font-size: 17px;
			line-height: 1.29;
			letter-spacing: -0.42px;
			color: #9da0a5;
		}
	}
}
.gapped { display: flex; flex-direction: column; gap: 16px; }

</style>
