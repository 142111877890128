<template>
	<span class="ContingentSlotFree">
		<span v-if="free == 1">1 spot left</span>
		<span v-else-if="free > 1">{{ free }} spots left</span>
		<span v-else-if="free == 0 && modelValue.total == 0">closed</span>
		<span v-else-if="free == 0">sold out</span>
		<span v-else-if="free == -1">1 spot overbooked</span>
		<span v-else-if="free < -1">{{ -free }} spots overbooked</span>
	</span>
</template>

<script lang="ts">
import ContingentSlot from './ContingentSlot.vue'

export default {
	components: { ContingentSlot },
	name: 'ContingentSlotFree',
	props: {
		modelValue: Object, // slot object
	},
	computed: {
		// we calculate this instead of using the slot.free property, so
		// we can manipulate the total and still show the right remaining.
		free() {
			return this.modelValue.total - this.modelValue.used
		},
	},
}
</script>

<style scoped>
.ContingentSlotFree { color: #999; font-size: 12px; }
</style>