<template>
	<div class="DataCalendar" v-if="dates.count > 0">
		<!-- TODO: also have this navigator at the bottom -->
		<div class="nav" style="white-space: nowrap;">
			<button @click="jumpToday" :disabled="offset == dates.offsetToday">
				<v-icon>mdi-calendar-today</v-icon> Today
			</button>
			<!--
			<button @click="jumpMenu = true;" style="display: flex;">
				<v-icon>mdi-magnify</v-icon>
				<v-text-field variant="outlined" autofocus hide-details class="jumpSearch"
					v-if="jumpMenu"
					v-model="jumpSearch"
					@keydown.enter="jump(jumpSearch)"
					@blur="jumpMenu = false"
					:placeholder="$t('text.dateJumpPlaceholder')"
				/>
			</button>
			-->
			<span v-if="navMode == 'SPAN'">
				<button @click="prevPage" :disabled="offset == 0">
					<v-icon>mdi-chevron-up</v-icon>
				</button>
				<button @click="nextPage" :disabled="offset >= dates.count - limit">
					<v-icon>mdi-chevron-down</v-icon>
				</button>
				<span v-if="calendar.length">
					{{ monthLabel(startMonthReferenceDay) }} {{ startMonthReferenceDay?.year }}
					<span v-if="!oneMonth">- {{ monthLabel(endMonthReferenceDay) }} {{ endMonthReferenceDay?.year }}</span>
				</span>
			</span>
			<span v-if="navMode == 'MONTH'">
				<span v-if="startMonthReferenceDay">
					<v-icon @click="prevPage" :disabled="offset == 0">mdi-chevron-left</v-icon>
					<v-menu v-model="monthMenu" location="bottom center">
						<template #activator="{ props }">
							<span class="month" v-bind="props">
								{{ monthLabel(startMonthReferenceDay, 'long') }}
								<v-icon>mdi-menu-down</v-icon>
							</span>
						</template>
						<v-list class="monthMenu" density="dense">
							<v-list-item @click="jumpToMonth(1)" :disabled="isMonthDisabled(1)">{{ monthLabel(1) }}</v-list-item>
							<v-list-item @click="jumpToMonth(4)" :disabled="isMonthDisabled(4)">{{ monthLabel(4) }}</v-list-item>
							<v-list-item @click="jumpToMonth(7)" :disabled="isMonthDisabled(7)">{{ monthLabel(7) }}</v-list-item>
							<v-list-item @click="jumpToMonth(10)" :disabled="isMonthDisabled(10)">{{ monthLabel(10) }}</v-list-item>

							<v-list-item @click="jumpToMonth(2)" :disabled="isMonthDisabled(2)">{{ monthLabel(2) }}</v-list-item>
							<v-list-item @click="jumpToMonth(5)" :disabled="isMonthDisabled(5)">{{ monthLabel(5) }}</v-list-item>
							<v-list-item @click="jumpToMonth(8)" :disabled="isMonthDisabled(8)">{{ monthLabel(8) }}</v-list-item>
							<v-list-item @click="jumpToMonth(11)" :disabled="isMonthDisabled(11)">{{ monthLabel(11) }}</v-list-item>

							<v-list-item @click="jumpToMonth(3)" :disabled="isMonthDisabled(3)">{{ monthLabel(3) }}</v-list-item>
							<v-list-item @click="jumpToMonth(6)" :disabled="isMonthDisabled(6)">{{ monthLabel(6) }}</v-list-item>
							<v-list-item @click="jumpToMonth(9)" :disabled="isMonthDisabled(9)">{{ monthLabel(9) }}</v-list-item>
							<v-list-item @click="jumpToMonth(12)" :disabled="isMonthDisabled(12)">{{ monthLabel(12) }}</v-list-item>
						</v-list>
					</v-menu>
					<v-icon @click="nextPage" :disabled="offset >= dates.count - limit">mdi-chevron-right</v-icon>

					<v-icon @click="jumpYears(-1)" :disabled="isYearDisabled(startMonthReferenceDay.year - 1)">mdi-chevron-left</v-icon>
					{{ startMonthReferenceDay?.year }}
					<v-icon @click="jumpYears(1)" :disabled="isMonthDisabled(startMonthReferenceDay.year + 1)">mdi-chevron-right</v-icon>
				</span>
				<span v-else>
					<button @click="jumpStart"><v-icon>mdi-arrow-left</v-icon></button>
					<button @click="jumpEnd"><v-icon>mdi-arrow-right</v-icon></button>
				</span>
			</span>
			<slot name="navigation"></slot>
		</div>
		<div style="display: flex; flex-wrap: wrap; font-size: smaller;">
			<div v-for="weekday of weekdays" :key="weekday"
				class="date weekdayHead"
			>
				{{ $t('text.' + weekday) }}
			</div>
			<div v-for="day of calendar" :key="'dt-' + day.date"
				class="date"
				:class="{
					open: day.open,
					notInRange: !day.inRange,
					monthBorder: day.date.substring(8, 10) < 8,
					today: dates.today == day.date,
					[ 'd' + day.date.substring(8, 10) ]: true,
					selected: selectedDay?.date == day.date,
					error: !!day.error,
				}"
				@click="clickDay(day)"
			>
				<span class="day">{{ day.date.substring(8,10).replace(/^0/, '') }}</span>
				<span v-if="day.date.substring(8,10) == '01' || day.weekday == 'monday'" class="monthLabel">
					{{ monthLabel(day) }}
					<span v-if="day.date.substring(8,10) == '01'">{{ day.year }}</span>
				</span>

				<v-icon class="dots hover" v-if="selectedDay?.date != day.date && showDayMenu">mdi-dots-horizontal</v-icon>
				<v-menu v-if="selectedDay?.date == day.date && showDayMenu"
					v-model="dayMenu"
					location="top center"
					:close-on-content-click="false"
				>
					<template #activator="{ props }">
						<v-icon v-bind="props" class="dots" v-if="!dayMenu">mdi-dots-horizontal</v-icon>
						<v-icon v-bind="props" class="dots" v-if="dayMenu">mdi-dots-horizontal-circle</v-icon>
					</template>
					<div class="DataCalendarMenu">
						<div style="font-weight: bold; font-size: smaller;">{{ day.date.substring(8,10) }} {{ monthLabel(day) }} {{ day.year }}</div>
						<slot name="dayMenu" :day="day"></slot>
					</div>
				</v-menu>
				<slot name="day" :day="day" :selectedDay="selectedDay"></slot>
			</div>
			<div v-if="dates.count > 0 && navMode == 'SPAN'">
				<button v-if="!extended" @click="$emit('update:extended', true)"><v-icon>mdi-arrow-expand-down</v-icon> {{ $t('text.showMore') }}</button>
				<button v-if="extended" @click="$emit('update:extended', false)"><v-icon>mdi-arrow-collapse-up</v-icon> {{ $t('text.showLess') }}</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Sugar from '../../lib/Sugar/Sugar'
import moment from 'moment'

export default {
	props: {
		dates: Object,
		calendar: Array,
		limit: { type: Number, default: 7 * 8 }, // X weeks page size
		offset: { type: Number, default: 0 }, // TODO: set the page containing today (if there is one)
		extended: { type: Boolean, default: false },
		clickOpensMenu: { type: Boolean, default: true },
		// SPAN: we can show longer time frames, next and prev jump by limit
		// MONTH: we always show a full month, next and prev jump by 1 month
		navMode: { type: String, default: 'SPAN' }, // MONTH | SPAN
		showDayMenu: { type: Boolean, default: true },
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		editingDay: null,
		selectedDay: null,
		dayMenu: false,
		jumpMenu: false,
		jumpSearch: '',
		monthMenu: false,
	}),
	computed: {
		// TODO: these indexes may be out of bounds - handle that!
		startMonthReferenceDay() {
			const l = this.calendar.length
			return this.calendar[l > 6 ? 6 : l]
		},
		startMonthLabel() {
			return this.monthLabel(this.startMonthReferenceDay)
		},
		// 28 days (january) = 4 weeks (of 6 weeks we are showing) => 15 days to the end of the month
		endMonthReferenceDay() {
			const i = this.calendar.length-15
			return this.calendar[i < 0 ? 0 : i]
		},
		endMonthLabel() {
			return this.monthLabel(this.endMonthReferenceDay)
		},
		oneMonth() { return this.startMonthLabel == this.endMonthLabel && this.startMonthReferenceDay.year == this.endMonthReferenceDay.year },
	},
	methods: {
		jump(input) {
			// TODO: time zones..
			Sugar.Date.setLocale(this.$store.state.selectedLocale)
			let date = Sugar.Date.create(input)
			//console.log('date', date)
			// for past dates we add a year
			if (date < Sugar.Date.create('1 month ago')) date = moment(date).add(1, 'year').toDate()
			const originalDate = moment(date).format('YYYY-MM-DD')
			// respect bounds
			if (date < this.dates.fromDate.toDate()) date = this.dates.fromDate.toDate()
			if (date > this.dates.toDate.toDate()) date = this.dates.toDate.toDate()
			//console.log('date', date)

			if (isNaN(date.getTime())) return

			const min = this.dates.fromMonday.valueOf()
			let offset = Math.floor((new Date(date).getTime() - min) / (1000 * 60 * 60 * 24))
			offset = offset - (offset % this.limit)
			this.$emit('update:offset', offset)

			const boundedDate = moment(date).format('YYYY-MM-DD')
			console.log('originalDate', originalDate, 'boundedDate', boundedDate)
			if (originalDate == boundedDate) {
				this.$nextTick(() => {
					this.selectedDay = this.calendar.find(d => d.date == originalDate)
					this.dayMenu = true
				})
			}

			this.jumpMenu = false
			this.jumpSearch = ''
		},
		monthLabel(monthOrDateOrDay, format: 'short' | 'long' | 'narrow' = 'short') {
			if (!monthOrDateOrDay) return
			let d = monthOrDateOrDay
			if (typeof monthOrDateOrDay == 'number')
				d = moment().month(monthOrDateOrDay - 1).toDate()
			else if (monthOrDateOrDay.dateObject)
				d = monthOrDateOrDay.dateObject
			const locale = this.$vuetify.locale.current
			return Intl.DateTimeFormat(locale, { month: format }).format(d)
		},
		clickDay(day) {
			this.selectedDay = day
			this.selectedTime = null
			if (this.clickOpensMenu) {
				this.dayMenu = true
				this.addTime = null
			}
		},
		jumpToMonth(month: number) {
			const d = moment(this.startMonthReferenceDay.dateObject)
			d.startOf('month').month(month-1).startOf('isoWeek')
			const diff = d.diff(this.calendar[0].dateObject, 'days')
			const offset = this.offset + diff
			this.$emit('update:offset', offset < 0 ? 0 : offset)
		},
		// January = 1
		isMonthDisabled(month: number) {
			if (!this.startMonthReferenceDay) return true
			const d = moment(this.startMonthReferenceDay.dateObject)
			d.startOf('month').month(month-1)
			if (d > this.dates.toDate) return true
			d.endOf('month')
			if (d < this.dates.fromDate) return true
			return false
		},
		jumpYears(years: number) {
			if (!this.startMonthReferenceDay) return
			const d = moment(this.startMonthReferenceDay.dateObject)
			d.startOf('month').add(years, 'year').startOf('isoWeek')
			const diff = d.diff(this.calendar[0].dateObject, 'days')
			const offset = this.offset + diff
			this.$emit('update:offset', offset < 0 ? 0 : offset)
		},
		jumpMonths(months: number) {
			// TODO: handle this better
			if (!this.startMonthReferenceDay) return
			const d = moment(this.startMonthReferenceDay.dateObject)
			d.startOf('month').add(months, 'month').startOf('isoWeek')
			const diff = d.diff(this.calendar[0].dateObject, 'days')
			const offset = this.offset + diff
			this.$emit('update:offset', offset < 0 ? 0 : offset)
		},
		isYearDisabled(year: number) {
			if (!this.startMonthReferenceDay) return true
			const d = moment(this.startMonthReferenceDay.dateObject)
			d.startOf('month').year(year)
			if (d > this.dates.toDate) return true
			d.endOf('year')
			if (d < this.dates.fromDate) return true
			return false
		},
		jumpStart() {
			this.$emit('update:offset', 0)
		},
		jumpEnd() {
			this.$emit('update:offset', this.dates.count - this.limit)
		},
		prevPage() {
			if (this.navMode == 'SPAN')
				this.$emit('update:offset', this.offset - this.limit)

			this.jumpMonths(-1)
		},
		nextPage() {
			if (this.navMode == 'SPAN')
				this.$emit('update:offset', this.offset + this.limit)

			this.jumpMonths(1)
		},
		jumpToday() {
			if (this.navMode == 'SPAN')
				return this.$emit('update:offset', this.dates.offsetToday)

			const d = moment().startOf('day').startOf('month').startOf('isoWeek')
			const offset = d.diff(this.dates.fromMonday, 'days')
			this.$emit('update:offset', offset < 0 ? 0 : offset)
		},
	},
	mounted() {
		this.jumpToday()
	},
}
</script>

<style scoped>
.nav { display: flex; gap: 10px; align-items: center; position: sticky; top: 64px; background: white; z-index: 100; padding-top: 15px; padding-bottom: 10px; margin-top: -15px; }
.nav button { padding: 5px; border: 1px solid #999; border-radius: 5px; background-color: #fff; }
.nav button[disabled] { opacity: 0.5; cursor: default; }
.nav .month { min-width: 110px; display: inline-block; text-align: center; cursor: pointer; }
.nav .month .v-icon { position: absolute; }
.monthMenu { column-count: 3; }
.monthMenu .v-list-item { text-align: center; }

.weekdayHead { width: 100%; text-align: center; color: #999; margin-bottom: 5px; min-height: initial !important; }
.monthLabel { font-weight: bold; color: #999; }

.date { width: calc(100% / 7); box-sizing: border-box; padding: 3px 5px 3px 5px; cursor: pointer; position: relative; overflow: hidden; min-height: 60px; }
.date { border-top: 1px solid #ddd; border-left: 1px solid #ddd; border-right: 1px solid #ccc; border-bottom: 1px solid #ccc; margin-right: -1px; margin-bottom: -1px; }
.date.weekdayHead { border-color: transparent; }
.date.monthBorder { border-top: 3px solid #999; }
.date.open { background-color: #fff6c6; }
.date.error { background-color: #fbd5d5; }
.date.notInRange { opacity: 0.3; }
.date.d01 { }
.date.selected { outline: 2px solid var(--col-selected); z-index: 10; }

.date .day { margin-right: 4px; font-size: 15px; min-width: 25px; text-align: center; display: inline-block; }
.date.today .day { display: inline-block; background-color: rgb(222, 45, 45); color: white; font-weight: bold; padding: 2px 4px; margin-left: -4px; border-radius: 15px; }

.dots { float: right; }
.date > .dots.hover { display: none; }
.date:hover > .dots.hover { display: block; }

.jumpSearch { width: 100px; }
.jumpSearch >>> .v-field { height: 24px; }
.jumpSearch >>> .v-field__input { padding: 3px 5px; min-height: 100%; font-size: smaller; }
</style>

<style>
.DataCalendarMenu { padding: 5px 0; background: white; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.2); }
.DataCalendarMenu > div { white-space: nowrap; cursor: pointer; padding: 5px 10px; }
.DataCalendarMenu > .item:hover { background: #eee; }
</style>